<template>
  <div class="vg_wrapper">
    <el-card>
      <!--      <div class="vd_search_group">
        <el-form :inline="true" size="mini" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getDentsNow()">
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="材料采购号:" prop="modr_no">
                <el-input size="small" v-model.trim="searchForm.modr_no" clearable placeholder="请填写材料采购号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="采购经办人:" prop="puca_stff_name">
                <el-input size="small" v-model.trim="searchForm.puca_stff_name" clearable placeholder="请填写采购经办人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="发货状态:" prop="dent_status">
                <el-select filterable v-model="searchForm.dent_status" placeholder="请选择单据状态" size="small" clearable>
                  <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="材料名称:" prop="modr_no">
                <el-input size="small" v-model.trim="searchForm.mtrb_name" clearable placeholder="请填写材料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="收货方简称:" prop="supp_babbr">
                <el-input size="small" v-model.trim="searchForm.supp_babbr" clearable placeholder="请填写收货方简称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="16">
              <el-form-item label="要求交货日期:">
                <el-date-picker
                  size="small"
                  v-model="timeValue"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="16">
              <el-form-item label="发货日期:">
                <el-date-picker
                  size="small"
                  v-model="timeValue2"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" class="vg_ml_16" @click="getDentsNow()">查询</el-button>
                <el-button size="small" type="info" icon="el-icon-refresh-right" class="vd_export" @click="buttonRefresh()">刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>-->
      <div class="vd_button_group vg_mb_8">
        <el-button
          v-if="userInfo.acct_id !== 1"
          size="small"
          type="success"
          class="vd_export"
          @click="combinedSendOut()"
          :disabled="sendOutStatus"
          >发货</el-button
        >
        <el-button size="small" type="info" icon="el-icon-refresh-right" class="vd_export" @click="buttonRefresh()">刷新</el-button>
        <!--	      <el-button  size="small" type="success" class="vd_export" @click="combinedSendOut()">合并发货</el-button>-->
      </div>
      <el-row>
        <el-col :md="24">
          <search-table
            ref="multiTable"
            :data="tableData"
            :tableRowKey="'dent_id'"
            :columns="tableProperties"
            v-loading="loadFlag"
            :totalPage="totalPage"
            :need-fixed-height="true"
            :pageSize="50"
            @select="selectRows"
            @selection-change="handleSelectionChange"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            @getTableData="getDentsList"
          >
            <template v-slot:modr_no="scope">
              <el-link type="primary" @click="goMatrOder(scope.row)"> {{ scope.row.modr_no }}</el-link>
            </template>
            <template v-slot:dent_status="scope">
              <el-tag v-if="scope.row.dent_status === 0" type="info" size="small">未发货</el-tag>
              <el-tag v-else-if="scope.row.dent_status === 1" type="warning" size="small">部分发货</el-tag>
              <el-tag v-else-if="scope.row.dent_status === 2" size="small">已发货</el-tag>
              <el-tag v-else-if="scope.row.dent_status === 3" type="success" size="small">已收货</el-tag>
              <el-tag v-else-if="scope.row.dent_status === 4" type="success" size="small">已结算</el-tag>
            </template>
          </search-table>
          <!--
          <el-table
            class="vg_cursor"
            ref="multiTable"
            :data="tableData"
            stripe
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="序号" align="center" width="60">
              <template v-slot="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="送货单号" prop="dent_no" show-overflow-tooltip align="center" />
            <el-table-column label="成品采购号" prop="podr_no" show-overflow-tooltip align="center" />
            <el-table-column label="材料采购号" prop="modr_no" width="200" show-overflow-tooltip align="center">
              <template v-slot="scope">
                <el-link type="primary" @click="goMatrOder(scope.row)"> {{ scope.row.modr_no }}</el-link>
              </template>
            </el-table-column>
            <el-table-column label="收货方简称" prop="supp_babbr" show-overflow-tooltip align="center">
              <template v-slot="scope">
                <span v-if="scope.row.supp_babbr">
                  {{ scope.row.supp_babbr }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="修改次数" prop="update_num" width="80" align="center"> </el-table-column>
            <el-table-column label="客户简称" prop="cust_abbr">
              <template v-slot="scope">
                <span v-if="scope.row.cust_abbr">{{ scope.row.cust_abbr }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="签订日期" prop="scon_cust_nos" align="center">
              <template v-slot="scope">
                <span v-if="scope.row.dent_date">{{ scope.row.dent_date | formatDate1 }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="订单数量" prop="dent_num" align="center" />
            <el-table-column label="已发次数" prop="grant_num" show-overflow-tooltip align="center" />
            <el-table-column label="已发数量" prop="dent_ynum" show-overflow-tooltip align="center" />
            <el-table-column label="要求交货日期" prop="deli_date" align="center">
              <template v-slot="scope">
                <span v-if="scope.row.deli_date">{{ scope.row.deli_date | formatDate1 }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="采购经办人" prop="puca_stff_name" align="center" />
            <el-table-column label="发货状态" show-overflow-tooltip align="center">
              <template v-slot="scope">
                <el-tag v-if="scope.row.dent_status === 1" type="warning" size="mini">部分发货</el-tag>
                <el-tag v-else-if="scope.row.dent_status === 0" type="info" size="mini">未发货</el-tag>
                <el-tag v-else-if="scope.row.dent_status === 2" type="success" size="mini">已发货</el-tag>
                <el-tag v-else type="danger" size="mini">已失效</el-tag>
              </template>
            </el-table-column>
            &lt;!&ndash;            <el-table-column label="操作" prop="tran_usdrate" align="center">&ndash;&gt;
            &lt;!&ndash;              <template v-slot="scope"> &ndash;&gt;
            &lt;!&ndash;                <el-button v-if="userInfo.acct_id !== 1" size="mini" type="warning" :disabled="scope.row.dent_status !== 0 && scope.row.dent_status !== 1" plain @click="deliverGoods(scope.$index)">发货&ndash;&gt;
            &lt;!&ndash;                </el-button>&ndash;&gt;
            &lt;!&ndash;								<span  v-if="userInfo.acct_id === 1" class="vg_9f9a9a">暂无</span>&ndash;&gt;
            &lt;!&ndash;              </template>&ndash;&gt;
            &lt;!&ndash;            </el-table-column>&ndash;&gt;
          </el-table>-->
        </el-col>
      </el-row>
      <el-dialog title="请选择物流方式" :visible.sync="dialogVisible" width="30%">
        <el-radio v-model="radio" label="1">快递物流</el-radio>
        <el-radio v-model="radio" label="2" class="vg_mb_8">自主运输</el-radio>
        <div>
          <el-form v-if="radio === '2'" ref="transForm" size="mini" :rules="rules" :model="transForm" label-width="120px">
            <el-row>
              <el-col class="vg_mb_8">
                <el-form-item label="司机姓名" prop="name">
                  <el-input v-model="transForm.name" placeholder="请输入司机姓名"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="vg_mb_8">
                <el-form-item label="司机手机号" prop="telPhone">
                  <el-input v-model="transForm.telPhone" placeholder="请输入司机手机号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="nextStep">下一步</el-button>
        </span>
      </el-dialog>
      <!--合并发货Dialog-->
      <el-dialog :visible.sync="sendOutDialogVisible" center width="30%">
        <div style="text-align: center">
          <span v-if="multiSelection.length === 1" style="font-size: 22px">是否确定去发货?</span>
          <span v-else style="font-size: 22px">是否确定<span style="color: #b13838">去合并发货</span>?</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="sendOutDialogVisible = false"> 否 </el-button>
          <el-button size="small" type="primary" @click="jumpJ"> 是 </el-button>
        </span>
      </el-dialog>
      <!--      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination" />
        </el-col>
      </el-row>-->
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { dentAPI } from '@api/modules/dent';
import helper from '@assets/js/helper.js';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/SearchTable.vue';
import { getDateNoTime } from '@assets/js/dateUtils';

export default {
  name: 'DentList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { label: '送货单号', prop: 'dent_no', itemType: 'input', input: false, sortable: false, labelWidth: '150px' },
        { label: '成品采购号', prop: 'podr_no', itemType: 'input', input: true, sortable: true, widthAuto: true },
        { label: '材料采购号', prop: 'modr_no', itemType: 'input', input: true, sortable: true, labelWidth: '160px' },
        { label: '收货方简称', prop: 'supp_babbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '修改次数', prop: 'update_num', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
        {
          label: '签订日期',
          prop: 'dent_date',
          itemType: 'date',
          sortable: true,
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        },
        { label: '订单数量', prop: 'dent_num', itemType: 'input', input: false, sortable: true, widthAuto: true },
        { label: '已发次数', prop: 'grant_num', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '已发数量', prop: 'dent_ynum', itemType: 'input', input: false, sortable: false, widthAuto: true },
        {
          label: '要求交货日期',
          prop: 'deli_date',
          itemType: 'date',
          sortable: true,
          labelWidth: '140px',
          formatter: val => getDateNoTime(val, true)
        },
        { label: '采购经办人', prop: 'puca_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
        {
          label: '发货状态',
          prop: 'dent_status',
          itemType: 'select',
          options: [
            { value: 0, label: '未发货' },
            { value: 1, label: '部分发货' },
            { value: 2, label: '已发货' },
            { value: 3, label: '已收货' },
            { value: 4, label: '已结算' }
          ],
          fixed: 'right',
          sortable: false,
          widthAuto: true,
          formatter: val => (val === 0 ? '未发货' : val === 1 ? '部分发货' : '已发货')
        }
      ],
      tableData: [],
      searchForm: {
        modr_no: null,
        puca_stff_name: null,
        dent_status: null,
        supp_babbr: null
      },
      transForm: {
        name: null,
        telPhone: null
      },
      timeValue: [],
      timeValue2: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      statusList: [
        { id: 0, label: '未发货' },
        { id: 1, label: '部分发货' },
        { id: 2, label: '已发货' }
      ],
      dialogVisible: false,
      radio: '1',
      rules: {
        name: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        telPhone: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      chooseForm: {},
      sendOutDialogVisible: false,
      userInfo: {},
      sendOutStatus: false
    };
  },
  mounted() {
    this.userInfo = this.$cookies.get('userInfo');
    if (this.$route.query.modr_no) {
      this.searchForm.modr_no = this.$route.query.modr_no;
    }
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/dent_edit') {
        this.initData();
      }
    }
  },
  filters: {
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    initData() {
      this.getDentsList();
    },
    getDentsList() {
      this.loadFlag = true;
      getNoCatch(dentAPI.getDents, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getDentsList();
    },
    // 发货
    deliverGoods(val) {
      this.dialogVisible = true;
      this.chooseForm = this.tableData[val];
    },
    // 下一步
    nextStep() {
      if (this.radio === '1') {
        this.dialogVisible = false;
        this.dbClickJp(this.chooseForm);
      } else if (this.radio === '2') {
        this.$refs['transForm'].validate(valid => {
          if (valid) {
            this.radio = '1';
            this.dialogVisible = false;
            this.jump('/dent_edit', {
              perm_id: this.$route.query.perm_id,
              form_id: this.chooseForm.dent_id,
              autoFlag: 1,
              name: this.transForm.name,
              telPhone: this.transForm.telPhone
            });
          } else {
            this.$message.warning('请把司机姓名和手机号补充完整');
          }
        });
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
      this.isGroupShip();
    },
    // 判断选择的数据是否可以触发发货按钮（1.先是未发货的 2.未发货且收货方简称同一个）
    isGroupShip() {
      let supBabbr = this.multiSelection.map(item => Object.values(item.supp_babbr).toString());
      let dentStat = this.multiSelection.map(item => item.dent_status);
      let supBabbrName = new Set(supBabbr).size;
      let dentStatNum = new Set(dentStat);
      if (this.multiSelection.length > 1) {
        if (supBabbrName > 1 || dentStatNum.has(2)) {
          this.sendOutStatus = true;
        } else {
          this.sendOutStatus = false;
        }
      } else if (this.multiSelection.length === 1) {
        if (dentStatNum.has(2)) return (this.sendOutStatus = true);
        else return (this.sendOutStatus = false);
      } else {
        this.sendOutStatus = false;
      }
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/dent_edit', { perm_id: this.$route.query.perm_id, form_id: row.dent_id, autoFlag: 0 });
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getDentsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 发货/合并发货
    combinedSendOut() {
      if (this.multiSelection.length < 1) return this.$message.warning('请选中至少一条数据!');
      this.isGroupShip();
      // if (this.multiSelection.length < 2) return this.$message.warning("请选择至少选择两条数据!")
      // let supp_abbr = this.multiSelection.map(({supp_abbr}) => (Object.values({supp_abbr}).toString()))
      // let dent_status = this.multiSelection.map(({dent_status}) => (Object.values({dent_status}).toString()))
      // if (new Set(supp_abbr).size > 1) return this.$message.warning("收货方不一致!")
      // let dentStatusSet = new Set(dent_status);
      // if (dentStatusSet.has('1') || dentStatusSet.has('2')) return this.$message.warning("只有未下发的可以合并")
      this.sendOutDialogVisible = true;
    },
    // 合并发货跳转
    jumpJ() {
      let ids = this.multiSelection.map(({ dent_id }) => Object.values({ dent_id }).toString()).join(',');
      this.sendOutDialogVisible = false;
      this.jump('/dent_edit', { perm_id: this.$route.query.perm_id, form_id: this.$route.query.perm_id, ids: ids, autoFlag: 0 });
    },
    // 跳转到材料采购号详情
    goMatrOder(row) {
      this.jump('/puca_edit', { perm_id: 138, form_id: row.puca_id, supp_abbr: row.supp_babbr });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>

<template>
  <div>
    <el-form>
      <div :class="tableRowKey" :style="needFixedHeight ? 'height:' + tableMaxHeight + 'px' : null">
        <el-table
          border
          :height="needFixedHeight ? tableMaxHeight + 'px' : null"
          :stripe="stripe"
          ref="multiTable"
          :data="tableData"
          :row-key="tableRowKey"
          :key="tableKey"
          v-bind="$attrs"
          v-on="$listeners"
          :header-cell-style="headerCellStyle"
          :cell-style="cellStyle"
          :row-style="rowStyle"
          :row-class-name="rowClassName"
          @select="tableSelect"
          @selection-change="tableSelectionChange"
          @select-all="tableSelectAll"
          @row-dblclick="dbClickJp"
          @row-click="rowClick"
          @sort-change="sortChange"
          @cell-click="cellClick"
        >
          <!--表格追加一行具名插槽-->
          <template v-if="$slots.append" #append>
            <slot name="append" />
          </template>
          <template v-for="(item, index) in tableColumn">
            <el-table-column
              v-if="['selection', 'index'].includes(item.prop)"
              :key="`${item.prop}-if`"
              v-bind="item"
              :width="item.labelWidth ? item.labelWidth : item.label ? item.label.length * 20 + 30 : 50"
              align="center"
              :column-key="`columnKey${index}`"
            >
              <template v-slot:header="scope">
                <span v-if="$scopedSlots[`header-${item.prop}`]">
                  <slot :name="`header-${item.prop}`" v-bind="scope" />
                </span>
                <span v-else>{{ scope.column.label }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :column-key="`columnKey${index}`"
              :key="item.prop"
              v-bind="item"
              :sortable="item.sortable === true ? 'custom' : item.sortable === false ? false : item.sortable"
              :width="
                item.widthAuto
                  ? item.widthAuto
                  : item.labelWidth
                  ? item.labelWidth
                  : item[`sum_${item.prop}`] && item[`sum_${item.prop}`].toString().length > item.label.length * 2
                  ? item[`sum_${item.prop}`].toString().length * 10
                  : item.label.length * 1.3 * 20
              "
              :fixed="item.fixed ? item.fixed : false"
              :align="item.align ? item.align : 'center'"
              :show-overflow-tooltip="item.overflowTooltip ? item.overflowTooltip : false"
            >
              <template v-slot:header="scope">
                <div v-show="needSummation" :class="`summation ${item[`sum_${item.prop}`] ? 'summationBorderBottom' : ''}`">
                  {{ item[`sum_${item.prop}`] }}
                </div>
                <div class="daCenter">
                  <span :class="`${item.subItem ? (item.subItem.required ? 'vg_deep_red' : '') : ''}`">{{ scope.column.label }}</span>
                </div>
                <div @click="handleClick" v-if="needSearch">
                  <div v-if="$scopedSlots[`header-${item.prop}`]">
                    <slot :name="`header-${item.prop}`" v-bind="searchForm" />
                  </div>
                  <div v-else>
                    <el-date-picker
                      v-if="item.itemType === 'date'"
                      v-model="searchForm[item.prop]"
                      show-word-limit
                      type="date"
                      size="mini"
                      clearable
                      placeholder="请选择"
                      @change="getData"
                    >
                    </el-date-picker>
                    <el-input
                      v-if="item.itemType === 'input'"
                      size="mini"
                      :disabled="item.input === false"
                      v-model.trim="searchForm[item.prop]"
                      :placeholder="item.input === false ? '' : '请输入'"
                      clearable
                      @clear="getData"
                      @input="val => (val ? '' : getData(false))"
                      @keyup.enter.native="getData(index)"
                    />
                    <el-select
                      v-if="item.itemType === 'select'"
                      :multiple="item.multiple"
                      :clearable="item.clearable"
                      size="mini"
                      :multiple-limit="item.multipleLimit"
                      :name="item.name"
                      :placeholder="item.placeholder"
                      :allow-create="item.allowCreate"
                      :disabled="item.disabled"
                      :filter-method="item.filterMethod"
                      :filterable="item.filterable"
                      :loading-text="item.loadingText"
                      :no-data-text="item.noDataText"
                      :popper-class="item.popperClass"
                      :no-match-text="item.noMatchText"
                      :loading="item.loading"
                      :remote="item.remote"
                      :remote-method="item.remoteMethod"
                      v-model="searchForm[item.prop]"
                      clearable
                      @change="getData"
                      @visible-change="typeof item.visibleChange === 'function' ? item.visibleChange($event) : () => {}"
                      @remove-tag="typeof item.removeTag === 'function' ? item.removeTag($event) : () => {}"
                    >
                      <el-option
                        v-for="item in item.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.disabled"
                        :style="selectOptionStyle"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </template>
              <template v-slot="scope">
                <span v-if="$scopedSlots[item.prop]">
                  <slot :name="item.prop" v-bind="scope" />
                </span>
                <div v-else-if="item.subItem">
                  <el-form-item
                    label-width="0"
                    :prop="`${tableRowKey}.${scope.$index}.${item.prop}`"
                    :rules="{ required: item.subItem.required, message: ' ' }"
                  >
                    <el-input
                      clearable
                      show-word-limit
                      size="mini"
                      v-if="item.subItem.type === 'input'"
                      v-model="scope.row[item.prop]"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(scope.row) : item.subItem.disabled"
                      :placeholder="item.subItem.input === false ? '' : '请输入'"
                      :maxlength="item.subItem.maxLength"
                      @input="event => (item.subItem.input ? item.subItem.input(event, scope.row) : () => {})"
                      @change="event => (item.subItem.change ? item.subItem.change(event, scope.row) : () => {})"
                      @blur="event => (item.subItem.blur ? item.subItem.blur(event, scope.row) : () => {})"
                    >
                      <template slot="append" v-if="$scopedSlots[`subItem-${item.prop}`]">
                        <slot :name="`subItem-${item.prop}`" v-bind="scope" />
                      </template>
                    </el-input>
                    <div v-if="item.subItem.type === 'image'">
                      <el-image
                        lazy
                        style="height: 75px"
                        :z-index="99999"
                        :initial-index="99999"
                        :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(scope.row) : item.subItem.image]"
                        :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(scope.row) : item.subItem.thumbnail"
                      />
                      <!--                      <el-popover :ref="`image${scope.$index}${item.prop}`" placement="right" width="500" trigger="hover">

                        <el-image :src="typeof item.subItem.image === 'function' ? item.subItem.image(scope.row) : item.subItem.image" />
                      </el-popover>-->
                    </div>
                  </el-form-item>
                </div>
                <span
                  v-else
                  v-html="
                    item.formatter
                      ? item.needOtherColumn
                        ? item.formatter(scope.row)
                        : item.formatter(scope.row[item.prop])
                      : scope.row[item.prop]
                      ? scope.row[item.prop]
                      : '<i class=\'el-icon-warning-outline\' style=\'color:#d4d5d6\'>暂无</i>'
                  "
                >
                </span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <el-pagination
        class="searchPagination"
        v-if="needPagination"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="currentPage"
        :total="totalPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        ref="pubPagination"
      >
      </el-pagination>
    </el-form>
  </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
  name: 'SearchTable',
  components: { Sortable },
  props: {
    columns: { type: Array, default: () => [] },
    stripe: { type: Boolean, default: true },
    pagination: { type: Object, default: () => ({}) },
    data: { type: Array, default: () => [] },
    loadFlag: { type: Boolean, default: false },
    needPagination: { type: Boolean, default: true },
    needSummation: { type: Boolean, default: false },
    needSearch: { type: Boolean, default: true },
    needFixedHeight: { type: Boolean, default: false },
    rowMove: { type: Boolean, default: false },
    columnMove: { type: Boolean, default: true },
    dbClickJp: { type: Function, default: () => {} },
    rowClick: { type: Function, default: () => {} },
    rowClassName: { type: Function, default: () => {} },
    rowKey: { type: Function || String, default: null },
    rowStyle: { type: Function || Object, default: ({ row, rowIndex }) => (row.index = rowIndex) },
    tableSelect: { type: Function, default: () => null },
    tableSelectionChange: { type: Function, default: () => null },
    tableSelectAll: { type: Function, default: () => null },
    totalPage: { type: Number, default: 0 },
    pageSize: { type: Number, default: 15 },
    getTableData: { type: Function, default: () => null },
    selectOptionStyle: String,
    tableRowKey: String,
    headerCellStyle: {
      type: Function || Object,
      default: () => {
        return {
          'background-color': '#f5f6f7',
          'font-size': '14px'
        };
      }
    },
    cellStyle: {
      type: Function || Object,
      default: () => {
        return {
          'font-size': '14px'
        };
      }
    }
  },
  computed: {
    tableData() {
      return this.data ?? [];
    },
    tableColumn() {
      return this.columns ?? [];
    }
  },
  data() {
    return {
      searchForm: {
        page_no: 1
      },
      tableKey: '',
      tableMaxHeight: 0,
      currentPage: 1
    };
  },
  async mounted() {
    await this.positionToRepair();
    await this.rowDrop();
    await this.columnDrop();
    let pageHeight = 0;
    await (pageHeight = this.needPagination ? 20 + this.$refs.pubPagination.$el.clientHeight : 0);
    await this.getTableMaxHeight(pageHeight);
  },
  methods: {
    getTableMaxHeight(pageHeight) {
      let e = document.getElementsByClassName(this.tableRowKey)[0];
      console.log(window.innerHeight);
      this.tableMaxHeight = window.innerHeight - e.getBoundingClientRect().y - e.getBoundingClientRect().height - 30 - pageHeight;
    },
    positionToRepair() {
      if (this.columnMove === false) return;
      let elements = document.getElementsByClassName('caret-wrapper');
      for (let i = 0; i < elements.length; i++) {
        elements[i].parentNode.children[1].appendChild(elements[i]);
      }
    },
    rowDrop() {
      if (this.rowMove === false) return;
      const tbody = document.querySelector(`.${this.tableRowKey} > .el-table__body-wrapper tbody`);
      const _this = this;
      Sortable.create(tbody, {
        // 结束拖拽后的回调函数
        onEnd({ newIndex, oldIndex }) {
          const currentRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currentRow);
          _this.reDrawTable();
        }
      });
    },
    async columnDrop() {
      if (this.columnMove === false) return;
      const _this = this;
      const wrapperTr = document.querySelector(`.${this.tableRowKey} .el-table__header-wrapper tr`);
      await Sortable.create(wrapperTr, {
        animation: 350,
        delay: 0,
        onEnd: evt => {
          const oldItem = this.tableColumn[evt.oldIndex];
          _this.tableColumn.splice(evt.oldIndex, 1);
          _this.tableColumn.splice(evt.newIndex, 0, oldItem);
          _this.reDrawTable();
        }
      });
      await this.positionToRepair();
    },
    reDrawTable() {
      this.tableKey = Math.random();
      this.$nextTick(() => {
        this.rowDrop();
        this.columnDrop();
      });
    },
    sortChange({ order, prop }) {
      if (!order) {
        this.searchForm.column = '';
        this.searchForm.order = '';
        return;
      }
      this.searchForm.column = prop;
      this.searchForm.order = order.substring(0, order.indexOf('ending'));
      this.getData();
    },
    handleClick(e) {
      e.cancelBubble = true;
    },
    getData(index) {
      if (this.needPagination) {
        this.currentPage = 1;
        this.searchForm.page_no = 1;
      }
      this.$emit('getTableData', index);
    },
    resetFields() {
      if (this.needPagination) {
        this.currentPage = 1;
        this.searchForm = { page_no: 1 };
        return;
      }
      this.searchForm = {};
    },
    cellClick(row, column, cell, event) {
      navigator.clipboard.writeText(event.target.innerText);
    },
    changePageSearch(val) {},
    sizeChangeHandle() {},
    currentChangeHandle(val) {
      this.searchForm.page_no = val;
      this.currentPage = val;
      this.$emit('getTableData');
    }
  }
};
</script>

<style scoped>
.summation {
  height: 20px;
  text-align: right;
  padding-right: 10px;
}
.summationBorderBottom {
  border-bottom: 1px solid #494a4d;
}
.daCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
}

.searchPagination {
  display: flex;
  justify-content: flex-end;
}
</style>
<style>
.changeColor {
  background: #f8e9db !important;
}
</style>
